<template>
  <section>
    <h2>Dashboard</h2>
    <div id="kandungan">
      <div id="menu-dashboard">
        <h3>Tasmi</h3>
        <nav>
          <a href="#"><i class="fas fa-plus-square"></i> Tambah Baharu</a>
          <a href="#"><i class="fas fa-list-alt"></i> Lihat Terkini</a>
        </nav>
        <h3>Murid</h3>
        <nav>
          <a href="#"><i class="fas fa-plus-square"></i> Tambah Murid</a>
          <a href="#"><i class="fas fa-list-alt"></i> Senarai Murid</a>
        </nav>
        <h3>Sistem</h3>
        <nav>
          <a href="#"><i class="fas fa-tools"></i> Urus Tetapan Sekolah</a>
          <a href="#"><i class="fas fa-plus-square"></i> Daftar Guru</a>
          <a href="#"><i class="fas fa-list-alt"></i> Senarai Guru</a>
        </nav>
      </div>
      <div id="dashboard">
        <h3>Halaqah Quran Saya</h3>
        <div id="senarai-halaqah-saya" v-if="sudahLoadHalaqahSaya" class="senarai-halaqah">
          <kad-halaqah v-for="halaqah in semuaHalaqahSaya" :key="halaqah.singkatan" :halaqah="halaqah" />
        </div>
        <div id="halaqah-tidak-dijumpai" v-else>
          Maaf, anda belum ada halaqah. 
        </div>

        <h3>Halaqah Quran Lain</h3>
        <div id="senarai-halaqah-lain" v-if="sudahLoadHalaqahSekolah" class="senarai-halaqah">
          <kad-halaqah v-for="halaqah in semuaHalaqah" :key="halaqah.singkatan" :halaqah="halaqah" />
        </div>
        <div id="halaqah-tidak-dijumpai" v-else>
          Maaf, tiada halaqah dijumpai di bawah sekolah anda. 
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import KadHalaqah from '../components/KadHalaqah.vue'
import { auth, db } from '../firebase'
import { doc, getDocs, getDoc, query, where, collection } from 'firebase/firestore'

export default defineComponent({
  components: { KadHalaqah },
  data() {
    return {
      sudahLoadHalaqahSaya: false,
      sudahLoadHalaqahSekolah: false,
    }
  },
  asyncComputed: {
    async semuaHalaqah () {
      const snapProfilSaya = await getDoc(doc(db, 'users', auth.currentUser.uid))
      const sekolahSaya = snapProfilSaya.data().sekolah
      console.log(sekolahSaya)
      const mohonSemuaHalaqah = query(collection(db, 'halaqah'), where('sekolah', '==', sekolahSaya))
      try {
        const semuaHalaqahSnapshot = await getDocs(mohonSemuaHalaqah)
        const semuaHalaqah = semuaHalaqahSnapshot.docs.map(snap => snap.data())
        console.log(semuaHalaqahSnapshot)
        this.sudahLoadHalaqahSekolah = true
        return semuaHalaqah 
      } catch (error) {
        console.log(mohonSemuaHalaqah)
        console.log(error)
        return []
      }
    },
    async semuaHalaqahSaya () {
      const emelGuru = auth.currentUser.email
      const mohonSemuaHalaqah = query(collection(db, 'halaqah'), where('emelGuru', '==', emelGuru))
      try {
        const semuaHalaqahSnapshot = await getDocs(mohonSemuaHalaqah)
        const semuaHalaqah = semuaHalaqahSnapshot.docs.map(snap => snap.data())
        this.sudahLoadHalaqahSaya = true
        return semuaHalaqah
      } catch (error) {
        console.log(error)
        return []
      }
    }
  },
})
</script>

<style lang="scss" scoped>
#kandungan {
  display: grid;
  grid-template-columns: 250px auto;
  padding: 20px;

  > * {
    margin: 10px;
  }

  #menu-dashboard {
    background-color: #f1f1f1;
    padding: 1rem;
    text-align: left;

    h3 {
      margin: 0;
      margin-top: 0.9rem;
      margin-bottom: 0.5rem;
      font-size: 0.8rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    nav {
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        padding:0.5rem;
        font-size: 0.8rem;
        font-weight: 600;
        color: darkcyan;

        .fas {
          color: orange;
          padding-right: 10px;
        }

        &:hover {
          background-color: white;
        }
      }
    }
  }

  #dashboard {
    text-align: left;

    h3 { 
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    .senarai-halaqah {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      text-align: left;
    }
  }
}
</style>