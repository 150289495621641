import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/halaqah/:id',
    name: 'SatuHalaqah',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "satuhalaqah" */ '../views/SatuHalaqah.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tetapan',
    name: 'Tetapan',
    component: () => import(/* webpackChunkName: "tetapan" */ '../views/Tetapan.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/installer',
    name: 'Installer',
    component: () => import(/* webpackChunkName: "installer" */ '../views/Installer.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/log-masuk',
    name: 'LogMasuk',
    component: () => import(/* webpackChunkName: "logmasuk" */ '../views/LogMasuk.vue')
  },
  {
    path: '/tentang',
    name: 'Tentang',
    component: () => import(/* webpackChunkName: "tentang" */ '../views/Tentang.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/log-masuk')
  } else {
    next()
  }
})

export default router