<template>
  <nav id="kawalan-tetapan">
    <div id="profil" v-if="diDalam">
      <div class="nama-pengguna">{{ user.displayName }}</div>
      <img :src="userPhoto" :alt="`Photo of ${user.displayName}`" class="gambar-pengguna">
      <div id="tetapan">
        <router-link to="/tetapan" id="butang-tetapan"><i class="fas fa-cog"></i></router-link>
      </div>
    </div>

    <div id="jika-di-luar" v-if="!diDalam">
      <button id="masuk" class="btn primary-box" @click="logMasuk"><i class="fas fa-sign-in-alt"></i></button>
    </div>

    <div id="jika-di-dalam" v-if="diDalam">
      <button id="keluar" class="btn danger-box" @click="logKeluar"><i class="fas fa-sign-out-alt"></i></button>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { auth } from '../firebase'
import { signInWithPopup, signOut, GoogleAuthProvider } from 'firebase/auth'

export default defineComponent({
  data() {
    return {
      //diDalam: false,
    }
  },
  computed:{
    user() {
      return auth.currentUser
    },
    userPhoto(){
      const user = auth.currentUser
      return user.photoURL
    },
    diDalam() {
      const user = auth.currentUser
      return user != null
    }
  },
  methods: {
    logMasuk() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(() => {
          this.success = true;
          this.$router.push("/")
          //this.diDalam = true
        })
        .catch(console.log)
    }, 
    logKeluar() {
      signOut(auth)
        .then(() => {
          this.$router.push("/tentang")
          //this.diDalam = false
        })
        .catch(console.log)
    }
  }
})
</script>

<style lang="scss" scoped>
#kawalan-tetapan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.5rem;

  > * {
    margin: 10px;
  }

  #tetapan {
    a#butang-tetapan { 
      font-size: 1.3rem;
      color: rgb(53, 53, 53);
    }
  }
}

#profil {
  display: flex;
  align-items: center;

  > * {
    padding: 0.5rem;
  }

  .nama-pengguna {
    text-align: right;
  }

  .gambar-pengguna {
    width:40px;
    border-radius: 500px;
  }
}
</style>