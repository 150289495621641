<template>
  <div class="bar-maju">
    <div class="maju" :style="`width: ${peratus}%; background-color: ${warna}`">&nbsp; </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: [ 'peratus', 'warna' ],
})
</script>

<style lang="scss" scoped>
.bar-maju {
  width: 100%;
  height:5px;
  background-color: rgb(226, 226, 226);
  border-radius: 3px;
  border: 1px solid rgb(109, 109, 109);
  padding: 0;

  .maju {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0.4rem;
  }
}
</style>