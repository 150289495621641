import Vue from 'vue'
import Vuex from 'vuex'

import { db } from '../firebase'
import { getDocs, collection } from 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    senaraiSurah: null,
    senaraiGred: null,
    senaraiLevel: null,
  },
  mutations: {
    setSenaraiSurah(state, senaraiSurah) {
      state.senaraiSurah = senaraiSurah
    },
    setSenaraiGred(state, senaraiGred) {
      state.senaraiGred = senaraiGred
    },
    setSenaraiLevel(state, senaraiLevel) {
      state.senaraiLevel = senaraiLevel
    },
  },
  actions: {
    async kemaskiniSenaraiSurah({ commit, state }) {
      if (state.senaraiSurah !== null) return

      const senaraiSurahSnap = await getDocs( collection(db, 'surah') )
      const senaraiSurah = []
      senaraiSurahSnap.forEach(surah => {
        senaraiSurah.push({
          id: parseInt(surah.id.replace(/\D/, "")), 
          ...surah.data()
        })
      })

      commit('setSenaraiSurah', senaraiSurah)
    },
    async kemaskiniSenaraiGred({ commit, state }) {
      if (state.senaraiGred !== null) return

      const senaraiGredSnap = await getDocs( collection(db, 'gred') )
      const senaraiGred = []
      senaraiGredSnap.forEach(gred => {
        senaraiGred.push(gred.data())
      })

      commit('setSenaraiGred', senaraiGred)
    },
    async kemaskiniSenaraiLevel({ commit, state }) {
      if (state.senaraiLevel !== null) return

      const senaraiLevelSnap = await getDocs( collection(db, 'level') )
      const senaraiLevel = []
      senaraiLevelSnap.forEach(level => {
        senaraiLevel.push(level.data())
      })

      commit('setSenaraiLevel', senaraiLevel)
    },
  },
  modules: {
  }
})
