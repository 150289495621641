// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore, query, getDoc, getDocs, setDoc, deleteDoc, doc, collection, where, Timestamp, onSnapshot } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmyd-E-BBKMEDdDyWcKVViicLaJ2dM8R8",
  authDomain: "tasmi-hafazan.firebaseapp.com",
  projectId: "tasmi-hafazan",
  storageBucket: "tasmi-hafazan.appspot.com",
  messagingSenderId: "43456862583",
  appId: "1:43456862583:web:e4f8968d5fbb25633fa972",
  measurementId: "G-K70TVRMJKY"
}

// Initialize Firebase
const fb = initializeApp(firebaseConfig)
const auth = getAuth(fb)
const db = getFirestore(fb)

// senarai pemalar
const dapatkanSemuaGred = async () => {
  const semuaGredSnap = await getDocs( collection( db, 'gred' ) )
  const semuaGred = []
  semuaGredSnap.forEach(gred => {
    semuaGred.push(gred.data())
  })
  return semuaGred.sort((gred1, gred2) => gred1.pointMula - gred2.pointMula)
}

var senaraiGred = []
dapatkanSemuaGred().then(hasil => {senaraiGred = hasil})

const dapatkanSemuaLevel = async () => {
  const semuaLevelSnap = await getDocs( collection( db, 'level' ) )
  const semuaLevel = []
  semuaLevelSnap.forEach(level => {
    semuaLevel.push(level.data())
  })
  return semuaLevel.sort((level1, level2) => level1.min - level2.min)
}

var senaraiLevel = []
dapatkanSemuaLevel().then(hasil => {senaraiLevel = hasil})

// create db helper functions
const dapatkanSemuaHalaqahSekolah = async (sekolah) => {
  const semuaHalaqahSnap = await getDocs(collection(db, `halaqah`), where('sekolah', '==', sekolah))
  const semuaHalaqah = []
  semuaHalaqahSnap.forEach(halaqahSnap => {
    semuaHalaqah.push({
      id: halaqahSnap.id,
      ...semuaHalaqah.data()
    })
  })
  return semuaHalaqah
}

const dapatkanSemuaHalaqahGuru = async (emailGuru) => {
  const semuaHalaqahSnap = await getDocs(collection(db, `halaqah`), where('guru', '==', emailGuru))
  const semuaHalaqah = []
  semuaHalaqahSnap.forEach(halaqahSnap => {
    semuaHalaqah.push({
      id: halaqahSnap.id,
      ...semuaHalaqah.data()
    })
  })
  return semuaHalaqah
}

const dapatkanSemuaHalaqahSaya = async () => {
  const user = auth.currentUser
  if (!user) return []
  const tetapanUserSnap = await getDoc(doc(db, `users/${user.uid}`))
  if (!tetapanUserSnap.exists()) return []
  const tetapanUser = tetapanUserSnap.data()

  return await dapatkanSemuaHalaqahSekolah(tetapanUser.sekolah)
}

const dapatkanSemuaMurid = async (halaqah, where) => {
  const semuaMuridSnap = await getDocs(collection(db, `halaqah/${halaqah}/murid`), where)
  const semuaMurid = []
  semuaMuridSnap.forEach(muridSnap => {
    semuaMurid.push({
      id: muridSnap.id,
      ...muridSnap.data()
    })
  })
  return semuaMurid
}

const dapatkanMurid = async (halaqah, murid, where) => {
  const muridSnap = await getDoc(doc(db, `halaqah/${halaqah}/murid/${murid}`), where)
  return {
    id: muridSnap.id,
    ...muridSnap.data()
  }
}

const dapatkanSemuaTasmi = async (halaqah, murid, where) => {
  const semuaTasmiSnap = await getDocs(collection(db, `halaqah/${halaqah}/murid/${murid}/tasmi`), where)
  const semuaTasmi = []
  semuaTasmiSnap.forEach(tasmiSnap => {
    semuaTasmi.push({
      id: tasmiSnap.id,
      ...tasmiSnap.data()
    })
  })
  return semuaTasmi
}

const dapatkanTasmi = async (halaqah, murid, tasmi, where) => {
  const tasmiSnap = await getDoc(doc(db, `halaqah/${halaqah}/murid/${murid}/tasmi/${tasmi}`), where)
  return {
    id: tasmiSnap.id,
    ...tasmiSnap.data()
  }
}

const dapatkanSemuaSurah = async () => {
  const semuaSurahSnap = await getDocs( collection(db, `surah`) )
  const semuaSurah = []
  semuaSurahSnap.forEach(surahSnap => {
    semuaSurah.push(surahSnap.data())
  })
  return semuaSurah
}

const dapatkanSemuaAyatBagiSurahBernombor = async (surah) => {
  const semuaAyatSurahSnap = await getDocs( collection(db, `surah/s${surah}/ayat`) )
  const semuaAyatSurah = []
  semuaAyatSurahSnap.forEach(surahSnap => {
    semuaAyatSurah.push(surahSnap.data())
  })
  return semuaAyatSurah
}

const dapatkanJulatAyat = async (surah, ayatMula, ayatAkhir) => {
  if (!surah) throw "Sila tentukan surah"
  if (!ayatMula) throw "Sila tentukan ayat mula"
  if (!ayatAkhir) throw "Sila tentukan ayat akhir"
  const mohonAyatPilihan = query(
    collection(db, `surah/s${surah}/ayat`), 
    where('nombor', '>=', ayatMula),
    where('nombor', '<=', ayatAkhir),
  )
  const ayatPilihanSnap = await getDocs(mohonAyatPilihan)
  const ayatPilihan = []
  ayatPilihanSnap.forEach(ayat => {
    ayatPilihan.push(ayat.data())
  })
  return ayatPilihan.sort((ayat1, ayat2) => ayat1.nombor - ayat2.nombor)
}

const dapatkanAyat = async (surah, ayat) => {
  const ayatSnap = await getDoc(doc(db, `surah/s${surah}/ayat/a${ayat}`))
  return ayatSnap.data()
}

const subSenaraiMurid = async (halaqahId) => {
  if (typeof halaqahId !== 'string') throw `Pastikan halaqah id adalah string : ${halaqahId} (${typeof halaqahId})`
  let senaraiMurid = []
  const muridRef = collection(db, `halaqah/${halaqahId}/murid/`)
  const unsubMurid = await onSnapshot(
    muridRef, 
    muridSnap => {
      muridSnap.docChanges().forEach(change => {
        const sortMurid = (murid1, murid2) => {
          /*
          if (murid1.gelaran == murid2.gelaran) return 0
          return murid1.gelaran < murid2.gelaran ? -1 : 1;
          */
          return (murid1.jumlahXP - murid2.jumlahXP) * -1
        }
        if (change.type === 'added') {
          senaraiMurid.push(change.doc.data())
          senaraiMurid.sort(sortMurid)
        }
        if (change.type === 'modified') {
          const indeksMuridYangBerubah = senaraiMurid.findIndex(murid => murid.matrik === change.doc.id)
          senaraiMurid[indeksMuridYangBerubah] = change.doc.data()
          senaraiMurid.sort(sortMurid)
        }
        if (change.type === 'removed') {
          senaraiMurid = senaraiMurid.filter(murid => murid.matrik !== change.doc.id)
        }
      })
    },
    console.log)

  return { senaraiMurid, unsubMurid }
}

const subSenaraiTasmi = async (halaqahId, muridId) => {
  if (typeof halaqahId !== 'string') throw `Pastikan halaqah id adalah string : ${halaqahId} (${typeof halaqahId})`
  if (typeof muridId !== 'string') throw `Pastikan murid id adalah string : ${muridId} (${typeof muridId})`
  let senaraiTasmi = []
  const tasmiRef = collection(db, `halaqah/${halaqahId}/murid/${muridId}/tasmi`)
  const unsubTasmi = await onSnapshot(
    tasmiRef, 
    tasmiSnap => {
      const sortTasmi = (tasmi1, tasmi2) => (tasmi1.ditasmiPada - tasmi2.ditasmiPada) * -1
      tasmiSnap.docChanges().forEach(change => {
        const cariIndeksTasmiYangBerubah = () => senaraiTasmi.findIndex(tasmi => tasmi.ditasmiPada === parseInt(change.doc.id))

        if (change.type === 'added') {
          senaraiTasmi.push(change.doc.data())
          senaraiTasmi.sort(sortTasmi)
        }
        if (change.type === 'modified') {
          const indeksTasmiYangBerubah = cariIndeksTasmiYangBerubah()
          senaraiTasmi[indeksTasmiYangBerubah] = change.doc.data()
          senaraiTasmi.sort(sortTasmi)
        }
        if (change.type === 'removed') {
          const indeksTasmiYangBerubah = cariIndeksTasmiYangBerubah()
          senaraiTasmi.splice(indeksTasmiYangBerubah, 1)
        }
      })
    },
    console.log)
    
  return {senaraiTasmi, unsubTasmi}
}

const hantarTasmi = async (halaqah, murid, data) => {
  // halaqah
  if (typeof halaqah !== 'string') throw "Pastikan medan halaqah adalah id jenis string" 

  // nilai murid wajib ada
  if (typeof murid !== 'object') throw "Pastikan murid adalah objek dengan nilai id, level, bilTasmi & jumlahXP"
  if (typeof murid.level !== 'object') throw "Pastikan murid.level adalah objek dengan nilai level & nama"
  if (typeof murid.level.level !== 'number') throw "Pastikan murid.level.level adalah nombor"
  if (typeof murid.level.nama !== 'string') throw "Pastikan murid.level.nama adalah string"
  if (typeof murid.bilTasmi !== 'number') throw "Pastikan murid.bilTasmi adalah nombor"
  if (typeof murid.jumlahXP !== 'number') throw "Pastikan murid.jumlahXP adalah nombor"

  // nilai tasmi WAJIB ada
  if (typeof data.surah !== 'object') throw "Pastikan surah diisi"
  if (typeof data.ayatMula !== 'number') throw "Nyatakan ayat mula"
  if (typeof data.ayatAkhir !== 'number') throw "Nyatakan ayat akhir"
  if (typeof data.silap !== 'object') throw "Pastikan objek silap ditetapkan, walaupun kosong"
  if (typeof data.silap.hafazan !== 'number') throw "Pastikan silap hafazan ditetapkan, walaupun kosong"
  if (typeof data.silap.jali !== 'number') throw "Pastikan silap jali ditetapkan, walaupun kosong"
  if (typeof data.silap.khafi !== 'number') throw "Pastikan silap khafi ditetapkan, walaupun kosong"

  // jika tiada, ini defaultnya
  if (!data.pentasmi) data.pentasmi = auth.currentUser.uid
  if (!data.ditasmiPada) data.ditasmiPada = Timestamp.now().seconds

  // kirakan bakinya
  data.jumlahAyat = kiraJumlahAyat(data.ayatAkhir, data.ayatMula)
  data.jumlahBaris = await kiraJumlahBaris(data.surah.id, data.ayatMula, data.ayatAkhir)
  data.kiraJumlahMukasurat = kiraJumlahMukasurat(data.jumlahBaris)
  data.skorMaks = kiraSkorMaks(data.jumlahBaris)
  data.skor = kiraSkor(data.silap, data.skorMaks)
  data.kualitiHafazan = kiraKualitiHafazan(data.skor, data.skorMaks)
  data.gredHafazan = kiraGredHafazan(data.kualitiHafazan, data.skor)
  data.xp = kiraXP(data.skor, data.kualitiHafazan, data.gredHafazan)

  const agregatBaharuMurid = kiraAgregatBaharuMurid(murid.jumlahXP, murid.bilTasmi, data.xp)

  const buatSemuaPromise = []
  const kemaskiniMurid = setDoc(doc(db, `halaqah/${halaqah}/murid/${murid.matrik}`), agregatBaharuMurid, { merge: true })
  const hantarTasmi = setDoc(doc(db, `halaqah/${halaqah}/murid/${murid.matrik}/tasmi/${data.ditasmiPada}`), data)
  buatSemuaPromise.push(kemaskiniMurid)
  buatSemuaPromise.push(hantarTasmi)
  return Promise.all(buatSemuaPromise)
}

const ubahTasmi = async (halaqah, murid, tasmi, data) => {
  return await setDoc(doc(db, `halaqah/${halaqah}/murid/${murid}/tasmi/${tasmi}`), data, { merge:true })
}

const padamTasmi = async (halaqahId, murid, tasmi, where) => {
  if (typeof halaqahId !== 'string') throw `Pastikan id halaqah jenis string : ${halaqahId} (${typeof halaqahId})`
  if (typeof murid !== 'object') throw `Pastikan murid adalah objek : ${typeof murid}`
  if (typeof murid.jumlahXP !== 'number') throw `Pastikan murid.jumlahXP adalah nombor : ${murid.jumlahXP} (${typeof murid.jumlahXP})`
  if (typeof murid.bilTasmi !== 'number') throw `Pastikan murid.bilTasmi adalah nombor : ${murid.bilTasmi} (${typeof murid.bilTasmi})`
  if (typeof tasmi !== 'object') throw `Pastikan murid adalah objek : ${typeof tasmi}`
  if (typeof tasmi.xp !== 'number') throw `Pastikan tasmi.xp adalah nombor : ${tasmi.xp} (${typeof tasmi.xp})`

  const agregatBaharu = kiraAgregatBaharuMurid(murid.jumlahXP, murid.bilTasmi, tasmi.xp, true)
  const padamTasmiIni = deleteDoc(doc(db, `halaqah/${halaqahId}/murid/${murid.matrik}/tasmi/${tasmi.ditasmiPada}`), where)
  const kemaskiniAgregatMurid = setDoc(doc(db, `halaqah/${halaqahId}/murid/${murid.matrik}`), agregatBaharu, { merge: true })
  return Promise.all([padamTasmiIni, kemaskiniAgregatMurid])
}

// calculation functions

const kiraJumlahAyat = (ayatAkhir, ayatMula) => {
  if (typeof ayatAkhir !== 'number' || typeof ayatMula !== 'number') throw `ayatMula (${ayatMula} = ${typeof ayatMula}) 
    ayatAkhir (${ayatAkhir} = ${typeof ayatAkhir})`
  return ayatAkhir - ayatMula + 1
}

const kiraJumlahBaris = async (surah, ayatMula, ayatAkhir) => {
  if (typeof surah !== 'number' || 
    typeof ayatMula !== 'number' || 
    typeof ayatAkhir !== 'number'
    ) throw `Kesilapan berikut perlu dipastikan adalah jenis nombor : 
      surah (${surah} = ${typeof surah}) 
      ayatMula (${ayatMula} = ${typeof ayatMula}) 
      ayatAkhir (${ayatAkhir} = ${typeof ayatAkhir}) `
  const ayatDalamJulat = await dapatkanJulatAyat(surah, ayatMula, ayatAkhir)
  const jumlahBaris = ayatDalamJulat.reduce((jumlah, ayat) => jumlah + ayat.bilBaris, 0)
  return  Math.round(jumlahBaris * 10) / 10
}

const kiraSkorMaks = (jumlahBaris) => {
  if (typeof jumlahBaris !== 'number') throw `Jumlah baris mestilah nombor : ${jumlahBaris} (${typeof jumlahBaris})`
  return Math.round(jumlahBaris * 10 / 15)
}

const kiraSkor = (silap, skorMaks) => {
  if (typeof silap !== 'object') throw `Parameter silap mestilah objek : ${typeof silap}`
  if (typeof silap.hafazan !== 'number' || 
    typeof silap.jali !== 'number' || 
    typeof silap.khafi !== 'number'
    ) throw `Kesilapan berikut perlu dipastikan adalah jenis nombor : 
      hafazan (${silap.hafazan} = ${typeof silap.hafazan}) 
      jali (${silap.jali} = ${typeof silap.jali}) 
      khafi (${silap.khafi} = ${typeof silap.khafi}) `
  if (typeof skorMaks !== 'number') throw `Skor maksimum perlu dipastikan adalah jenis nombor : ${skorMaks} (${typeof skorMaks})`

  const kadarSilap = {
    hafazan: 1,
    jali: 1,
    khafi: 0.5,
  }

  const skorTolakSilapHafazan = Math.round(skorMaks - silap.hafazan * kadarSilap.hafazan) 
  if (skorTolakSilapHafazan <= 0) return 0

  const skorTolakSilapJali = Math.round(skorTolakSilapHafazan - silap.jali * kadarSilap.jali) 
  if (skorTolakSilapJali <= 0) return 0

  const skorTolakSilapKhafi = Math.round(skorTolakSilapJali - silap.khafi * kadarSilap.khafi)
  if (skorTolakSilapKhafi <= 0) return 0

  return skorTolakSilapKhafi
}

const kiraJumlahMukasurat = (jumlahBaris) => {
  if (typeof jumlahBaris !== 'number') throw `Pastikan jumlah baris adalah nombor : ${jumlahBaris}`
  return Math.round(jumlahBaris / 15 * 100) / 100
}

const kiraKualitiHafazan = (skor, skorMaks) => {
  if (typeof skor !== 'number' || typeof skorMaks !== 'number') `Pastikan skor (${skor} = '${typeof skor}') dan skorMaks (${skorMaks} = '${typeof skorMaks}') adalah nombor.`
  return Math.round(skor / skorMaks * 100)
}

const kiraGredHafazan = (kualitiHafazan, skor) => {
  if (!Array.isArray(senaraiGred)) throw `Senarai gred mestilah sebuah array : ${senaraiGred}`
  if (typeof kualitiHafazan !== 'number') throw `Pastikan kualiti hafazan adalah nombor: ${kualitiHafazan}`
  if (typeof skor !== 'number') throw `Pastikan skor adalah nombor : ${skor}`

  const copySenaraiGred = senaraiGred.map(gred => gred)

  const gredIkutSkor = copySenaraiGred
    .sort((gred1, gred2) => (gred1.pointMula - gred2.pointMula))
    .filter(gred => skor >= gred.skorMin)
    .pop()

  const gredIkutKualitiHafazan = copySenaraiGred
    .filter(gred => {
      return (((kualitiHafazan < gred.pointAkhir) && (kualitiHafazan >= gred.pointMula)) || 
      ((kualitiHafazan == 100) && (gred.pointAkhir == 100)))
    })
    .pop()
  
  const gredDiberi = gredIkutSkor.pointAkhir >= gredIkutKualitiHafazan.pointAkhir ? gredIkutKualitiHafazan : gredIkutSkor;

  return gredDiberi.gred
}

const kiraXP = (skor, kualitiHafazan, gredHafazan) => {
  if (!Array.isArray(senaraiGred)) return -1
  if (typeof skor !== 'number') return -1
  if (typeof kualitiHafazan !== 'number') return -1
  if (typeof gredHafazan !== 'string' || gredHafazan == "Z") return -1

  const xpDaripadaSkor = skor * 20
  const xpDaripadaKualitiHafazan = kualitiHafazan

  const xpDaripadaGred = senaraiGred
    .filter(gred => gred.gred == gredHafazan)
    .pop()
    .pointAkhir

  return xpDaripadaSkor + xpDaripadaKualitiHafazan + xpDaripadaGred
}

const dapatkanLevelMurid  = (xp) => {
  if (typeof xp !== 'number') throw `Pastikan XP adalah nombor : ${xp} (${typeof xp})`
  const semuaLevel = senaraiLevel
  const infoLevel = semuaLevel.filter(level => 
    (level.min <= xp) && (level.maks > xp)
  )[0]

  console.log(xp)
  return {
    level: infoLevel.level,
    nama: infoLevel.nama,
  }
}

const kiraAgregatBaharuMurid = (jumlahXPAsal, bilTasmiAsal, tasmiXP, tolak) => {
  tolak = tolak | false
  const jumlahXP = jumlahXPAsal + tasmiXP * (tolak ? -1 : 1)
  const bilTasmi = bilTasmiAsal + (tolak ? -1 : 1)
  const level = dapatkanLevelMurid(jumlahXP)
  return { bilTasmi, jumlahXP, level }
}

const k = {
  kiraJumlahAyat, 
  kiraJumlahBaris,
  kiraJumlahMukasurat,
  kiraSkorMaks,
  kiraSkor,
  kiraKualitiHafazan,
  kiraGredHafazan,
  kiraXP
}

export {
  fb,
  auth,
  db,

  senaraiGred,
  senaraiLevel,

  dapatkanSemuaHalaqahGuru,
  dapatkanSemuaHalaqahSekolah,
  dapatkanSemuaHalaqahSaya,

  dapatkanSemuaMurid,
  dapatkanMurid,
  subSenaraiMurid,
  //tambahMuridBaharu,

  dapatkanSemuaTasmi,
  dapatkanTasmi,
  hantarTasmi,
  ubahTasmi,
  padamTasmi,
  subSenaraiTasmi,

  k,

  dapatkanSemuaSurah,
  dapatkanSemuaAyatBagiSurahBernombor,
  dapatkanJulatAyat,
  dapatkanAyat,
}