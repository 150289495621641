<template>
  <div class="kad">
    <div class="kepala">
      <h3>{{ halaqah.nama }}</h3>
    </div>
    <div class="badan">
      <div>Murid</div>
      <div>{{ halaqah.bilMurid }} orang</div>
      <bar-maju warna="orange" :peratus="100"/>
    </div>
    <div class="kaki">
      <router-link :to="`/halaqah/${idHalaqah}`" title="Lihat senarai halaqah"><i class="fas fa-eye"></i></router-link> | 
      <router-link :to="`/halaqah/${idHalaqah}/tambah-murid`" title="Tambah murid"><i class="fas fa-plus"></i></router-link> | 
      <router-link :to="`/halaqah/${idHalaqah}/urus`" title="Urus halaqah"><i class="fas fa-pen"></i></router-link> | 
      <router-link :to="`/halaqah/${idHalaqah}/padam`" title="Padam halaqah" class="pautan-padam"><i class="fas fa-trash-alt"></i></router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import BarMaju from './BarMaju.vue'

export default defineComponent({
  props: [ 'halaqah' ],
  components: { BarMaju },
  computed: {
    idHalaqah() {
      return `${this.halaqah.sekolah}-${this.halaqah.singkatan.toLowerCase()}`
    }
  }
})
</script>
<style lang="scss" scoped>
.kad {
  margin: 10px;
  padding: 0;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    margin: 0;
    padding: 10px;
  }

  .kepala {
    border-bottom: 1px solid #aaaaaa;
    margin: 0;
    text-align: center;

    h3 {
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      padding: 0.5rem 0;
      margin: 0;
    }
  }

  .badan {
    display: grid;
    grid-template-columns: 25% 30% auto;
    align-items: center;
    font-size: 0.9rem;

    > * {
      margin: 5px 0;
    }
  }

  .kaki {
    background-color: #eeeeee;
    text-align: center;

    a {
      text-decoration: none;
      font-size: 0.8rem;
      color: blue;

      &:hover {
        text-decoration: underline;
      }

      &.pautan-padam {
        color:red;
      }
    }
  }
}
</style>