<template>
  <div id="app">
    <section id="main-nav" class="navbar">
      <div id="logo-title">
        <h1>
          <a href="/">Tasmi Hafazan</a>
        </h1>
      </div>
      <nav class="nav-utama">
        <router-link to="/">Dashboard</router-link>
        <router-link to="/tentang">Tentang</router-link>
      </nav>
      <kawalan-tetapan/>
    </section>
    <router-view/>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import KawalanTetapan from './components/KawalanTetapan.vue'

export default defineComponent({
  components: { KawalanTetapan },
  setup() {
    
  },
})
</script>


<style lang="scss">
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

section {
  &.navbar {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 250px auto 380px;
    align-items: center;
    background-color: #eeeeee;
    border-bottom: #aaa solid 1px;

    #logo-title {
      h1 {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-family: Montserrat, arial, sans-serif;
        font-weight: 600;
        letter-spacing: 0.1rem;
        margin-left: 20px;

        a {
          color:darkcyan;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    nav.nav-utama {
      text-align: left;

      a {
        font-size: 0.8rem;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        padding: 0.5rem 1rem;

        color: #2c3e50;

        &:hover {
          background-color: white;
        }

        &.router-link-exact-active {
          color: #42b983;
          font-weight: 600;
        }
      }
    }

    nav.nav-tetapan {
      text-align: right;

      a {
        font-size: 1.2rem;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0.5rem 1rem;

        color: #2c3e50;

        &:hover {
          background-color: white;
        }

        &.router-link-exact-active {
          color: #42b983;
          font-weight: 600;
        }
      }
    }


    #kawalan-log {
      margin-right: 20px;
    }
  }
}

button {
  &.btn {
    padding:0.5rem 1.5rem;
    border-radius: 3px;
    border: 1px solid gray;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    cursor: pointer;
  }
}

/* define colors */
$primary: orange;
$secondary: skyblue;
$accent: turquoise;
$text-dark: black;
$text-light: white;
$danger: red;
$info: lightblue;

.primary-box     { background-color: $primary; color: $text-dark; border-color: $primary; &:hover { background-color: yellow; } }
.primary-outline { background-color: white;  color: rgb(104, 67, 0);   border-color: $primary; &:hover { background-color: rgb(255, 255, 209); } }

.secondary-box     { background-color: $secondary; color: $text-dark; border-color: $secondary; &:hover { background-color: rgb(78, 192, 236); } }
.secondary-outline { background-color: white;    color: rgb(0, 72, 100); border-color: $secondary; &:hover { background-color: rgb(201, 236, 250); } }

.danger-box     { background-color: $danger;  color: $text-light; border-color: $danger; &:hover { background-color: yellow; } }
.danger-outline { background-color: white;  color: $danger;     border-color: $danger; }

.info-box     { background-color: $info;    color: $text-dark;  border-color: $info; }
.info-outline { background-color: white;  color: $info;       border-color: $info; }

</style>
